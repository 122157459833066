.grid.grid {
  margin-top: 1rem;
  padding-left: 0rem;
  padding-right: 0rem;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
